<template>
  <section :id="id" :class="`detect_deviece_${deviceType} state_${deviceType}`">
    <NavBar :title="title" @go-back="goback" />
    <slot />
    <FootModuleLoader :themeConfig="themeConfig" :host="host" :deviceType="deviceType" />
  </section>
</template>

<script>
import NavBar from '@/pages/components/NavBar.vue';
import FootModuleLoader from '@/pages/components/FootModuleLoader.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    NavBar,
    FootModuleLoader
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'deviceType',
      'themeConfig',
      'host',
    ])
  },
  methods: {
    goback () {
      this.$router.go(-1);
    }
  },
};
</script>